.home-5
	background: url(../img/home-bg-3.jpg)
	background-repeat: no-repeat
	background-size: cover
	background-position: center
	.section-title
		background-image: linear-gradient(96deg, #f7d05b 5%, #faf2c4 38%, #d2a04f 93%) !important
	
.award-slider
	.item
		text-align: center
		.title
			color: #fff
			line-height: 1.5
			font-size: r(18px)
			margin-top: r(20px)
		.img
			height: r(200px)
			position: relative
			img
				position: absolute
				top: 50%
				left: 50%
				max-height: 100%
				transform: translate(-50%,-50%)