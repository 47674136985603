.project-category
	background: color(main)
	padding: r(30px) 0
	@media screen and ( max-width: 768.98px )
		padding: r(30px) 0

.filter
	display: flex
	align-items: center
	.title
		font-family: $font-2
		font-weight: 700
		font-size: r(20px)
		margin-right: 1rem
		text-transform: uppercase
	select
		width: r(260px)
		height: r(40px)
		padding: 0 r(20px)
		border: 1px solid rgba(#000 , 0.3)
		font-size: 16px
		background-color: transparent
	&.filter-white
		select
			border: 1px solid #fff
			color: #fff
			background-image: url(../img/sl-w.png)

.project-list
	@media screen and ( min-width: 1024.98px )
		padding-right: r(75px)

.project-item
	display: flex
	flex-wrap: wrap
	background: #333
	align-items: center
	.project-img
		flex: 0 0 420 / 735 * 100%
		height: 280px
		img
			width: 100%
			height: 100%
			object-fit: cover
	.project-caption
		flex: 1
		padding: r(30px)
	.project-title
		font-size: r(24px)
		line-height: 1.5
		font-weight: 700
		// max-width: r(200px)
		+text-gradient($gradient)
		margin-bottom: r(30px)
		font-family: $font-2
	@media screen and ( max-width: 576px )
		.project-img,.project-caption
			flex: 0 0 100%
		.project-caption
			padding: r(20px) r(15px)
		.project-title
			max-width: none
			margin-bottom: r(15px)
			
.project-item-wrap
	+ .project-item-wrap
		margin-top: r(30px)