//Reset
@import src/_plugins/sass-libraries/reset.sass

//Gridflex
$gl-gridName: row
$gl-gutter: r(30px)
$gl-gutter-vertical: r(30px)
$gl-mq-width: 'min-width'

@import src/_plugins/gridlex/src/gridlex.scss

// Global
body, html
	font-size: 13px
	line-height: 1
	font-family: 'Commissioner', sans-serif
	color: #333
	@media (min-width: 1280px)
		font-size: 14px
	@media (min-width: 1440px)
		font-size: 16px

body
	background: #1e1e1e

*,*:before,*:after
	box-sizing: border-box
	outline: none

a
	text-decoration: none

select
	background-image: url("../img/sl.png")
	background-repeat: no-repeat
	background-position: right 0.75rem center
	appearance: none

.fb_iframe_widget
	height: 20px
	span
		height: 20px !important
		width: 126px !important
	iframe
		height: 20px !important
		width: 126px !important


.fa-exclamation-triangle
	line-height: 2.5
	color: #ff3333
	font-weight: 400
	font-family: 'Montserrat', sans-serif
	&:before
		font-family: 'Font Awesome 5 Pro'

.frm-btn-reset
	display: none !important

.img-zoom
	overflow: hidden
	img
		transition: 0.3s all
	&:hover
		img
			transform: scale(1.05)

.toggle-item
	display: none