.wrap-form
	.row
		margin-bottom: -20px
		margin-left: -20px
		> *
			padding-bottom: 20px
			padding-left: 20px
	.form-group
		+ .form-group
			margin-top: r(18px)
		label
			display: inline-block
			color: color(main)
			margin-bottom: 10px
			line-height: 1.5
		input[type="text"],	textarea
			border: 1px solid #333333
			height: 50px
			width: 100%
			background: #151515
			padding: 0 r(20px)
			font-size: 14px
			color: #fff
		&::placeholder
			color: #fff
		textarea
			height: 285px
			padding: r(16px) r(20px)
	.frm-btnwrap
		text-align: right
	.frm-btn
		margin-top: r(18px)
		position: relative
		display: inline-block
		margin-left: auto
		&:after
			position: absolute
			top: 50%
			transform: translateY(-50%)
			content: "\f356"
			font-family: "Font Awesome 5 Pro"
			font-weight: 900
			right: 40px
			font-size: 14px
			pointer-events: none
		input[type="submit"]
			width: 150px
			height: 50px
			color: #fff
			background: transparent
			text-transform: uppercase
			font-size: 14px
			border: 2px solid
			border-image: $gradient
			border-image-slice: 1
			padding-right: 40px
			cursor: pointer
