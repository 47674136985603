.home-4
	overflow: hidden
	.text-bg
		font-family: $font-2
		line-height: 1.375
		display: inline-block
		position: relative
		font-weight: 700
		color: #fff
		h2
			font-size: r(60px)
			margin-bottom: r(16px)
			+text-gradient($gradient)

		p
			font-size: r(40px)
	.home-project-slider
		margin-top: r(40px)
		.item
			height: auto
			overflow: hidden
			position: relative
			&:before
				content: ''
				position: absolute
				width: 100%
				height: 100%
				top: 0
				left: 0
				background: rgb(255, 255, 255)
				background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 50%, rgba(0, 0, 0, 0.7) 100%)
				z-index: 1
				pointer-events: none
				transition: .3s all ease-in-out
				opacity: 0

			.img
				+img-scale(250 / 420 * 100%)
			.caption
				position: absolute
				z-index: 2
				color: #fff
				bottom: 0
				left: 0
				width: 100%
				padding: 10px
				line-height: 1.5
				opacity: 0
				transition: 0.3s all ease-in-out
			.title
				font-size: r(18px)
				font-weight: 700
			.view-more
				margin-top: r(16px)
			&:hover
				&:before
					opacity: 1
				.caption
					opacity: 1
	@media screen and ( max-width: 1024.98px )
		margin-top: 0
		.text-bg
			h2
				font-size: r(32px)
				margin-bottom: 0
			p
				font-size: r(20px)
		.leading-normal
			margin-top: r(16px) !important
		.home-project-slider
			.item
				&:before
					opacity: 1
				.caption
					opacity: 1