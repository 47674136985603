.section-title
	font-size: r(35px)
	line-height: 1.375
	font-weight: 700
	font-family: $font-2
	text-transform: uppercase
	display: inline-block
	&:not(.text-white):not(.text-main)
		+text-gradient($gradient)

	@media screen and ( max-width: 1024.98px )
		font-size: r(22px)
		margin-bottom: 1rem !important
