.wdw-detail-1
	position: relative
	z-index: 1
	background: #292929
	&::after
		content: ''
		background: color(main)
		position: absolute
		right: 0
		top: 0
		height: 100%
		width: 660 / 1920 * 100%
		z-index: -1
	@media screen and ( max-width: 1024.98px )
		&::after
			height: 30%
			width: 100%
			bottom: 0
			top: auto

.wdw-project-item
	.img
		+img-scale(340 / 600 * 100%)
	.title
		font-size: r(24px)
		font-family: $font-2
		line-height: 1.5
		color: #fff
		margin-top: r(16px)
		font-weight: 700
	@media screen and ( max-width: 576px )
		.title
			font-size: r(18px)
			margin-top: 0.5rem
