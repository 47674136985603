.btn
	border: 2px solid
	border-image: $gradient
	border-image-slice: 1
	color: white
	height: r(50px)
	display: inline-flex
	align-items: center
	justify-content: center
	min-width: r(200px)
	text-transform: uppercase
	font-size: r(14px)
	transition: 0.3s all
	em
		margin-left: r(20px)
	&:not([class*='background-'])
		&:hover
			background: $gradient
	&[class*='background-']
		border: 0
	&.background-gradient
		background: $gradient
	@media screen and ( max-width: 576px )
		min-width: auto
		padding: 0 r(15px)

.view-more
	display: inline-flex
	align-items: center
	font-weight: 700
	font-size: 14px
	color: #fff
	span
		margin-left: r(15px)
		transition: 0.3s all
		position: relative
		top: 2px
	&:hover
		color: color(main)
		span
			margin-left: r(20px)

.swiper-btn
	border: 1px solid #fff
	display: inline-flex
	align-items: center
	justify-content: center
	width: r(40px)
	height: r(40px)
	font-size: 14px
	border-radius: 50%
	color: #fff
	position: absolute
	top: 50%
	transform: translateY(-50%)
	cursor: pointer
	z-index: 2
	@media screen and ( min-width: 1024.98px )
		margin: 0 15px
		&.swiper-prev
			right: 100%
		&.swiper-next
			left: 100%
	@media screen and ( max-width: 1024.98px )
		display: none
		&.swiper-prev
			left: -5px
		&.swiper-next
			right: -5px
