.home-3
	background-repeat: no-repeat
	.item-list
		background-size: cover
		background-position: center
		min-height: 390px
		display: flex
		align-items: center
		padding: r(30px)
		background: url(../img/home-bg-1.jpg)
		position: relative
		z-index: 1
		&:before
			content: ''
			position: absolute
			width: 100%
			height: 100%
			background-color: rgba(0, 0, 0, 0.5)
			left: 0
			top: 0
			z-index: -1
		.row
			width: calc(100% + 1.875rem)
		.item
			text-align: center
			.icon
				height: 70px
				display: flex
				align-items: center
				justify-content: center
				img
					max-height: 100%
			.subtitle
				text-transform: uppercase
				font-size: r(35px)
				line-height: 1.375
				font-weight: 700
				+text-gradient($gradient)
				font-family: $font-2
				small
					font-size: 70%
			.title
				font-weight: 500
				font-size: r(18px)
				line-height: 1.375
				margin-top: r(4px)
				color: #fff
	@media screen and ( max-width: 1024.98px )
		.item-list
			.item
				.subtitle
					font-size: r(24px)