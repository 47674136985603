header
	z-index: 999
.header-transparent
	position: fixed
	top: 0
	left: 0
	width: 100%
	color: #fff
	transition: 0.3s all
	.header-top-wrap
		text-align: center
		padding: 24px 0
		position: relative
		&::before
			content: ''
			position: absolute
			bottom: 0
			left: 50%
			transform: translateX(-50%)
			background: rgba(#fff , 0.2)
			width: 1540px
			height: 1px
	.header-bottom
		padding-top: 24px
		padding-bottom: 24px
		display: flex
		justify-content: space-between
		align-items: center
	.menu
		> ul
			display: flex
			> li
				& + li
					margin-left: r(100px)
				> a
					font-size: r(17px)
					text-transform: uppercase
					font-weight: 800
				&.active
					> a
						color: color(main)
	.language-wrapper
		margin-left: r(80px)
	@media screen and ( min-width: 1024.98px )
		&.is-scrolling
			transform: translateY(-100%)

	@media screen and ( max-width: 1024.98px )
		display: none

.language
	line-height: 1.5
	a
		font-weight: 600
		color: #333
	.dropdown-content
		min-width: auto