.wdw-project-detail-1
	.item
		position: relative
		color: #fff
		.img
			+img-scale(300 / 600 * 100%)
		.title
			height: r(50px)
			width: r(400px)
			max-width: 100%
			display: flex
			align-items: center
			justify-content: center
			margin: -25px auto 0
			background: color(main)
			font-family: $font-2
			font-weight: 900
			font-size: r(17px)
			text-align: center
			position: relative
			z-index: 2
			text-transform: uppercase
			padding: 0 20px
		.brief
			text-align: center
			line-height: 1.5
			margin-top: r(20px)
	@media screen and ( max-width: 768px )
		.item
			.title
				position: static
				width: auto
				margin-top: 0

.wdw-project-detail-2
	background: #333
	color: #fff
	.content
		padding: r(60px) 0
		line-height: 1.375
	.title
		font-size: r(24px)
		font-family: $font-2
		margin-bottom: r(16px)
		text-transform: uppercase
		font-weight: 700
	.fullcontent
		margin-top: r(16px)
		ul
			li
				padding-left: 1em
				text-indent: -1em
				&:before
					content: "\f30b"
					padding-right: 12px
					font-size: r(14px)
					font-family: "Font Awesome 5 Pro"
					font-weight: 400
	@media screen and ( min-width: 1024.98px )
		margin-top: r(160px)
		padding-bottom: r(80px)
		.img
			position: relative
			margin-top: -160px
	@media screen and ( max-width: 1024.98px )
		.img
			img
				width: 100%
		.content
			padding: r(20px) 15px

.wdw-project-detail-3
	.item
		line-height: 1.5
		color: #fff
		.icon
			display: inline-flex
			align-items: center
			justify-content: center
			height: r(75px)
			width: r(75px)
			background: color(main)
		.title
			font-size: r(40px)
			font-family: $font-2
			margin-bottom: 8px
			margin-top: 8px
			font-weight: 700
	@media screen and ( max-width: 576px )
		.item
			text-align: center
			display: flex
			flex-direction: column
			align-items: center
			.title
				font-size: r(24px)
				margin-bottom: 0

.wdw-project-detail-4
	padding-top: 0 !important
	.gallery-list
		.row
			margin-bottom: -10px
			margin-left: -10px
			> *
				padding-bottom: 10px
				padding-left: 10px
	.gallery-item
		.gallery-img
			+img-scale(345 / 610 * 100%)