@function r($size)
	@return ($size/16px)*1rem

@function color($color) 
	@return map-get($colors, $color)


@mixin breakpoint-up($name)
	$min: map-get($breakpoints, $name)
	@media (min-width: $min)
		@content

=line($line)
	overflow: hidden
	display: -webkit-box
	text-overflow: ellipsis
	-webkit-line-clamp: $line
	-webkit-box-orient: vertical

=img-scale($ratio)
	padding-top: $ratio
	display: block
	position: relative
	img
		position: absolute
		width: 100%
		height: 100%
		top: 0
		left: 0
		object-fit: cover

=text-gradient($gradient)
	background-image: $gradient
	background-color: transparent
	-webkit-text-fill-color: transparent
	-webkit-background-clip: text