.news-side-list
	padding: r(30px)

.news-item
	line-height: 1.375
	display: block

.news-date
	font-size: r(14px)
	margin-bottom: r(10px)
	line-height: 1
	@media screen and ( max-width: 1024.98px )
		margin-bottom: r(4px)

.news-big
	position: relative
	.news-img
		height: 100%
		position: relative
		&:before
			content: ''
			background-image: linear-gradient( 180deg, rgba(0,0,0, 0) 0%, rgba(0,0,0,0.5) 100%)
			position: absolute
			top: 0
			left: 0
			width: 100%
			height: 100%
			z-index: 1
		img
			height: 100%
			width: 100%
			object-fit: cover
	.news-caption
		position: absolute
		bottom: 0
		padding: r(30px)
		left: 0
		width: 100%
		color: #fff
		z-index: 2
		transition: 0.3s all
	.news-title
		font-weight: 500
		font-size: r(24px)
		+line(2)
		margin-bottom: r(20px)
	@media screen and ( max-width: 1024.98px )
		.news-caption
			bottom: r(10px)
			padding: 0 r(15px)
		.news-title
			margin-bottom: r(8px)
	@media screen and ( min-width: 1024.98px )
		&:hover
			.news-caption
				background: color(main)
			.news-title
				text-decoration: underline

.news-side
	display: flex
	.news-img
		flex: 0 0 130/360*100%
		// height: 77px
		overflow: hidden
		img
			width: 100%
			height: 100%
			object-fit: cover
	.news-caption
		flex: auto
		padding-left: r(20px)
	.news-date
		margin-bottom: 8px
		color: color(main)
	.news-title
		font-size: r(15px)
		font-weight: 600
		line-height: r(20px)
		+line(3)
	@media screen and ( max-width: 1024.98px )
		.news-img
			height: 100%

.news-small
	background: #333
	transition: 0.3s all
	.news-caption
		color: #fff
		padding: r(30px)
	.news-title
		font-size: r(18px)
		font-weight: 600
		line-height: r(26px)
		+line(2)
	.news-img
		+img-scale(220 / 390 * 100%)
	.news-brief
		+line(3)
		font-size: r(14px)
		line-height: r(20px)
		margin-top: r(10px)
	.view-more
		color: #666
		margin-top: r(20px)
		transition: 0.3s all
	.news-date
		color: color(main)
	@media screen and ( min-width: 1024.98px )
		&:hover
			background: color(main)
			.news-date
				color: #fff
			.view-more
				color: #fff

.news-video,.news-gallery
	background: #333
	.news-caption
		color: #fff
		padding: r(30px)
	.news-img
		+img-scale(220 / 390 * 100%)
		background: #000
		position: relative
		img
			opacity: 0.7
		.icon
			position: absolute
			top: 50%
			left: 50%
			transform: translate(-50%,-50%)
			z-index: 2
			img
				opacity: 1
				position: static
				object-fit: initial
				width: auto
				height: auto
	.news-title
		font-size: r(18px)
		font-weight: 600
		line-height: r(26px)
		+line(2)
	.news-date
		color: color(main)

.news-list-aside
	padding: r(40px) 0
	background: #333
	color: #fff
	.section-title
		padding-bottom: r(20px)
		margin-left: r(30px)
		margin-right: r(30px)
		border-bottom: 1px solid color(main)
		margin-bottom: r(20px)
	.news-list-aside-wrap
		padding-left: r(30px)
		padding-right: r(30px)
	// 	@media screen and ( min-width: 1024.98px )
	// 		overflow: auto
	// 		max-height: 328px
	// 		&::-webkit-scrollbar
	// 			width: 4px
	// 			background: #666666
	// 		&::-webkit-scrollbar-thumb
	// 			background: color(main)
