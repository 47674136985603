.breadcrumb-banner-wrapper
	position: relative
	color: #fff

.breadcrumb-page-title
	position: absolute
	bottom: 0
	width: 100%
	left: 0
	padding-bottom: r(70px)

.page-title
	h1
		font-weight: 700
		font-size: r(48px)
		font-family: $font-2
		text-transform: uppercase

.news-detail-page,.recruitment-detail-page,.searchresults, .securepage
	.header-transparent
		background: #1d1d1d
	.breadcrumb-banner-wrapper
		padding-top: 200px
		// padding-bottom: r(20px)
		color: #333
	.banner-wrapper
		display: none
	.breadcrumb-wrapper
		margin-top: 0
	.breadcrumb-page-title
		position: static
		padding-bottom: 0
	.page-title
		display: none
	@media screen and ( max-width: 1024.98px )
		.breadcrumb-banner-wrapper
			padding-top: 110px

.securepage
	padding-top: 200px


@media screen and ( max-width: 768.98px )
	.banner
		+img-scale(62.5%)
	.breadcrumb-page-title
		padding-bottom: 15px
	.page-title
		h1
			font-size: r(30px)