.job-overview
	padding-top: r(30px)
	h3
		font-size: r(24px)
		line-height: r(30px)
		color: #fff
		margin-bottom: r(24px)
		text-transform: uppercase
		font-family: $font-2
	table
		width: 100%
		background: #333
		border-top: 2px solid color(main)
		color: #fff
		font-size: r(15px)
		line-height: 1.375
		tr
			td
				padding: r(16px) r(13px)
				&:first-child
					white-space: nowrap
					color: color(main)
			& + tr
				td
					border-top: 1px solid rgba(#fff , 0.1)

.job-description
	margin-top: r(60px)
	color: #fff
	h3
		font-size: r(24px)
		line-height: r(30px)
		color: #fff
		margin-bottom: r(24px)
		text-transform: uppercase
		font-family: $font-2
	ul
		list-style: none
		padding-left: 0 !important
		li
			padding-left: 1.3em
			text-indent: -1.35em
			list-style: none
			&:before
				content: "\f30b"
				padding-right: 12px
				font-size: r(14px)
				font-family: "Font Awesome 5 Pro"
				font-weight: 400
			+ li
				margin-top: r(12px)

.career-detail-btn
	padding: r(40px) r(30px)
	background: #333
	text-align: center
	> *
		width: 100%
		+ *
			margin-top: 1rem
	span
		font-size: r(14px)
		line-height: r(18px)
		display: block
		text-align: center
		color: #fff
		text-transform: uppercase

.career-detail-other
	.news-side
		.news-caption
			padding: 0
		.news-title
			font-size: r(18px)
			text-transform: uppercase
		.news-date
			margin-bottom: 0
			margin-top: r(12px)


.recruitment-detail-page 
	.fancybox-iframe
		width: 992px
.dialogpage
	margin: 0 auto
.apply-frm
	position: relative
	margin: 0
	background: #fff	
	line-height: 1.375
	padding: r(50px)
	input[type="file"]
		left: 0
	h4
		font-size: r(24px)
		color: color(main)
		font-weight: 700
		margin-bottom: r(24px)

	.row
		margin-left: r(-20px)
		margin-bottom: r(-20px)
		> *
			padding-left: r(20px)
			padding-bottom: r(20px)

	.fa-exclamation-triangle[style="display: inline;"]
		display: block !important
		top: 50%
		transform: translateY(-50%)
		right: 15px
		color: red
		position: absolute
	.label
		font-size: 14px
		font-weight: 400
		margin-bottom: 10px
		display: none
		.required
			display: inline-block
	.attachfile1
		.label
			display: inline-block
			.required
				display: inline
	.attachfile2
		margin-top: 15px
	input[type="text"], input[type="password"], textarea
		width: 100%
		border: 1px solid #c8c8c8
		padding: 0 r(20px)
		font-size: 1rem
		&::placeholder
			color: #999
	input[type="text"]
		height: r(53px)
	textarea
		height: r(153px)
		padding-top: r(12px)
	.RadAsyncUpload
		width: auto
		.ruDropZone
			display: none
		.ruFileWrap
			position: relative
			display: block
			height: 45px
		.ruFileInput, .ruBrowse
			width: r(190px)
			height: r(40px)
			background: color(light-blue)
			color: #fff
			text-transform: uppercase
			font-size: 14px
			display: flex
			justify-content: center
			align-items: center
			cursor: pointer
			border: 0
		.ruFileInput
			position: absolute
			top: 0
			opacity: 0

	h3
		font-weight: 700
		padding: 0 20px
		font-size: 18px

	.frm-btn
		text-align: center
		input[type="submit"]
			width: r(140px)
			height: r(45px)
			border: 0
			font-size: 14px
			font-weight: 700
			cursor: pointer
			background: $gradient
			color: #fff
	@media screen and ( max-width: 1024.98px )
		padding: r(30px)