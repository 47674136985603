.about-1
	table
		width: 100%
		background: #333
		border-top: 2px solid color(main)
		font-size: r(14px)
		line-height: 1.375
		color: #fff
		tr
			td
				padding: r(16px) r(13px)
				&:first-child
					white-space: nowrap
					color: color(main)
					font-size: r(15px)
			+ tr
				td
					border-top: 1px solid rgba(#fff , 0.1)
	.item
		display: flex
		.icon
			flex: 0 0 48px
			height: 48px
			display: flex
			align-items: center
			justify-content: center
		.content
			padding-left: r(20px)
		.number
			font-family: $font-2
			font-weight: 900
			font-size: r(48px)
			color: color(main)
			font-feature-settings: 'lnum', 'pnum'
		.title
			color: #fff
			margin-top: r(8px)
			text-transform: uppercase
			line-height: 1.375
	@media screen and ( min-width: 1024.98px )
		.items-list
			padding-left: r(40px)
	@media screen and ( max-width: 576px )
		.item
			display: block
			text-align: center
			.content
				padding-left: 0
				margin-top: 8px
				.number
					margin-bottom: 5px

.about-2
	background: url(../img/about-bg-1.jpg)
	background-repeat: no-repeat
	background-size: cover
	background-position: center
	padding: r(40px) 0
	.item
		color: #fff
		text-align: center
		.icon
			margin-bottom: r(24px)
		.title
			font-size: r(24px)
			font-family: $font-2
			font-weight: 700
			padding-bottom: r(20px)
			border-bottom: 1px solid rgba(#fff , 0.1)
			margin-bottom: r(20px)
		.content
			line-height: 1.5
	@media screen and ( min-width: 1024.98px )
		padding: r(170px) 0
		min-height: 720px
		.item
			max-width: 455px
			text-last: left

.about-3
	background-image: url(../img/about-bg-2.jpg)
	background-repeat: no-repeat
	background-size: contain
	background-position: bottom center
	.items-list
		position: relative
		.background
			// height: 610px
			img
				height: 100%
				width: 100%
				object-fit: cover
		.overlay
			position: absolute
			top: 0
			left: 0
			width: r(390px)
			height: 100%
			background: rgba(#000 , 0.7)
			color: #fff
		.item
			display: flex
			padding: r(15.5px) r(20px)
			align-items: center
			line-height: 1.375
			font-family: $font-2
			transition: 0.3s all
			.number
				font-weight: 900
				font-size: r(40px)
				color: rgba(#fff , 0.4)
				font-feature-settings: 'lnum'
			.title
				font-size: r(20px)
				padding-left: r(30px)
			+ .item
				border-top: 1px solid rgba(#fff , 0.2)
			&:hover
				background: color(main)
	@media screen and ( min-width: 1024.98px )
		min-height: 1270px
	@media screen and ( max-width: 768.98px )
		.items-list
			.overlay
				width: 100%
				height: auto
				position: static


.about-4
	position: relative
	z-index: 2
	&::after
		content: ''
		background-image: url(../img/about-bg-3.jpg)
		background-repeat: no-repeat
		background-size: cover
		background-position: bottom center
		position: absolute
		top: 0
		left: 0
		width: 100%
		height: 100%
		z-index: 1
	.content-wrap
		position: relative
		z-index: 3
	ul
		font-weight: 600
		li
			+ li
				margin-top: r(12px)
	.img-wrap
		position: relative
		padding: r(20px)
		&::after
			content: ''
			background: #fff
			position: absolute
			top: 0
			left: 0
			width: 100%
			height: 100%
			z-index: 1
		.img
			position: relative
			z-index: 3
			box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.1)
			img
				width: 100%
	@media screen and ( min-width: 1024.98px )
		padding-bottom: 100px !important
		.img-wrap
			margin-top: -150px
	@media screen and ( max-width: 1024.98px )
		.img-wrap
			padding: 0
			&::after
				display: none

.about-5
	// background: url(../img/about-bg-4.jpg)
	// background-repeat: no-repeat
	// background-size: cover
	// background-position: center
	.staff-item
		.img
			+img-scale(570 / 495 * 100%)
		.content
			padding: r(40px) r(30px)
			line-height: 1.375
			background: rgba(#000 , 0.7)
		.name
			font-size: r(30px)
			font-family: $font-2
			color: #fff
			font-weight: 700
		.subtitle
			text-transform: uppercase
			color: color(main)
			font-weight: 700
			margin-top: r(12px)
	@media screen and ( max-width: 1024.98px )
		.staff-item
			.content
				padding: r(24px)
			.name
				font-size: r(24px)
	@media screen and ( min-width: 1024.98px )
		// min-height: 1260px
		.staff-item
			.content
				margin-left: r(60px)
				margin-top: r(-90px)
				position: relative
				z-index: 2