.service-item
	position: relative
	.service-img
		+img-scale(515 / 915 * 100%)
	.service-caption
		position: relative
		margin-left: -180px
		background: rgba(#333333 , 0.9)
		padding: r(60px) r(40px)
		color: #fff
	.service-title
		margin-bottom: r(30px)
	+ .service-item
		margin-top: r(60px)
	&:nth-child(2n)
		.row
			flex-direction: row-reverse
		.service-caption
			margin-left: 0
			margin-right: -180px
	@media screen and ( max-width: 1024.98px )
		.service-caption
			margin: 0 !important
			padding: r(20px) r(15px)
		.service-title
			margin-bottom: r(16px)
		& + .service-item
			margin-top: r(30px)