.home-1
	.links
		> span
			display: inline-block
			margin: 0 14px
	.who-we-are-slider
		margin-top: r(60px)
		.swiper-slide
			height: auto
	.item
		height: 100%
		display: flex
		flex-direction: column
		.img
			+img-scale(100%)
			border-radius: 50%
			overflow: hidden
		.caption
			color: #fff
			padding: r(20px)
			z-index: 2
			line-height: 1.5
			text-align: center
			display: flex
			flex-direction: column
			justify-content: space-between
			align-items: center
			flex: 1 1 0%
		.title
			font-family: $font-2
			font-size: r(18px)
			font-weight: 600
			text-transform: uppercase
			margin-bottom: r(8px)
			transition: 0.5s all
	.swiper-navigation
		display: flex
		align-items: center
		justify-content: space-between
	.swiper-pagination-wrap
		align-items: center
		width: 60%
		margin: r(60px) auto 0
	.swiper-pagination
		width: 100%
		height: 1px
		position: static
		margin: 0 r(30px)
		background: transparent
		.swiper-pagination-progressbar-fill
			background: color(main)
	@media screen and ( min-width: 1440.98px )
		.swiper-navigation-wrap
			.swiper-btn
				margin: 0 15px
	@media screen and ( max-width: 1024.98px )
		.who-we-are-slider
			margin-top: r(30px)
		.item
			&::after
				height: 100%
			.title
				height: auto
			.brief
				max-height: none
				+line(3)
		.swiper-pagination-wrap
			display: none