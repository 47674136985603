.header-bg
	position: fixed
	top: 0
	left: 0
	width: 100%
	padding: 20px r(90px)
	background: #fff
	transition: 0.3s all
	.header-wrap
		display: flex
		align-items: center
		justify-content: space-between
	.logo
		img
			height: 40px
	.header-bg-left
		display: flex
		justify-content: space-between
		align-items: center
	.menu
		> ul
			display: flex
			> li
				& + li
					margin-left: r(90px)
				> a
					font-weight: 700
					text-transform: uppercase
				&.active
					> a
						color: color(main)

	.language-wrapper,.util-wrapper
		margin-left: r(80px)
	.util-wrapper
		align-items: center
	@media screen and ( min-width: 1024.98px )
		transform: translateY(-100%)
		&.is-scrolling
			transform: none
	@media screen and ( max-width: 1440.98px )
		padding: 20px r(30px)
		.menu
			> ul
				> li
					& + li
						margin-left: r(60px)
		.language-wrapper,.util-wrapper
			margin-left: r(60px)
	@media screen and ( max-width: 1024.98px )
		position: static
		.language-wrapper,.util-wrapper
			margin-left: 30px
	@media screen and ( max-width: 576px )
		padding: 20px 15px

.has-sub
	position: relative
	display: flex
	align-items: center
	> span
		margin-left: 5px
	.sub-menu
		display: none
		position: absolute
		top: 100%
		left: 0
		z-index: 2
		padding-top: 10px
		ul
			box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15)
			color: #333
			background: rgba(#fff , 0.8)
			padding: r(16px)
			li
				line-height: 1.5
				+ li
					margin-top: 1rem
				a
					white-space: nowrap
					display: flex
					align-items: center
					line-height: 1.5
					font-weight: 500
				&.active
					a
						text-decoration: underline
						color: color(main)
	@media screen and ( max-width: 1024.98px )
		justify-content: space-between
		flex-wrap: wrap
		> span
			font-size: 16px
		.sub-menu
			position: static
			width: 100%
			margin-top: 10px
			border-top: 0
			box-shadow: none
			ul
				padding: 0
				background: transparent
				font-size: 14px
				color: #fff
				box-shadow: none
				padding-left: r(20px)
				border-left: 1px solid 1px
				li
					a
						white-space: initial
						display: inline-block
	@media screen and ( min-width: 1024.98px )
		.toggle
			display: none
		&:hover
			.sub-menu
				display: block
		.sub-menu
			ul
				li
					a:hover
						text-decoration: underline

.menu-toggle
	display: flex
	align-items: center
	justify-content: center
	width: 40px
	height: 40px
	color: #fff
	font-size: 18px
	background: $gradient
	margin-left: 30px

.offcanvas-overlay
	position: fixed
	top: 0
	left: 0
	right: 0
	bottom: 0
	background: rgba(#000, 0.3)
	display: none
	z-index: 998

.menu-mobile-offcanvas
	color: #fff
	width: 270px
	left: -270px
	background: linear-gradient(135deg,rgba(152,100,41,1),rgba(236,183,82,1))
	&.is-open
		transform: translate(270px,0,0)
	@media screen and ( min-width: 1024.98px )
		display: none
	.offcanvas-close
		display: none
	.offcanvas-wrap
		padding: r(40px) r(20px) r(40px)
		position: relative
	.menu
		text-transform: uppercase
		> ul
			display: block
			> li
				+ li
					margin-left: 0
					margin-top: 40px
				> a
					color: #fff
					font-size: 16px
					font-weight: 700
