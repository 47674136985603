.career-wrap
	color: #fff
	.social
		a
			margin-right: 5px
			font-size: r(24px)
	@media screen and ( min-width: 1024.98px )
		padding-right: r(75px)


.faq-item
	background: #333333
	+ .faq-item
		margin-top: 10px
	.heading
		padding: r(15px) r(20px)
		display: flex
		align-items: center
		transition: 0.3s all
		cursor: pointer
		text-transform: uppercase
		font-weight: 700
		&.active
			background: color(main)
			.arrow
				span:before
					content: '\f056'
	.title
		line-height: 1.5
	.arrow
		margin-left: auto
		transition: 0.3s all
	.body
		padding: r(20px)
		display: none
	@media screen and ( max-width: 768.98px )
		.heading
			padding: r(10px) r(15px)
		.body
			padding: r(15px)

.job-list
	table
		width: 100%
		background: #333
		line-height: 1.5
		tr
			th
				background: color(main)
				font-size: r(20px)
				text-transform: uppercase
				font-weight: 700
				padding: r(16px) r(20px)
				text-align: left
			td
				padding: r(16px) r(20px)
				a
					color: color(main)
				&:first-child
					white-space: nowrap
				&:last-child
					text-align: right
			+ tr
				td
					border-top: 1px solid rgba(#fff , 0.1)

.form-contact
	.qparagraph
		height: 100%
		textarea
			height: calc(100% - 33px) !important