$max: 10
$offset: 1
$unit: 'rem' // Feel free to change the unit.
@mixin list-loop($className, $breakpoint ,$styleName)
	$i: 1
	@while $i <= $max
		@if $breakpoint != ''
			#{$className + $breakpoint + $i}
				#{$styleName}: #{$i/4 + $unit}
			$i: $i + $offset
		@else
			#{$className + $i}
				#{$styleName}: #{$i/4 + $unit}
			$i: $i + $offset

// Margins
@include list-loop('.mt-', '' ,'margin-top')
@include list-loop('.mr-', '' , 'margin-right')
@include list-loop('.mb-', '' , 'margin-bottom')
@include list-loop('.ml-', '' , 'margin-left')
@include list-loop('.m-', '' , 'margin')

// Paddings
@include list-loop('.pt-', '' , 'padding-top')
@include list-loop('.pr-', '' , 'padding-right')
@include list-loop('.pb-', '' , 'padding-bottom')
@include list-loop('.pl-', '' , 'padding-left')
@include list-loop('.p-', '' , 'padding')

@each $breakpoint in map-keys($breakpoints)
	@include breakpoint-up($breakpoint)
		@include list-loop('.pt-', #{$breakpoint + '-'} , 'padding-top')
		@include list-loop('.pr-', #{$breakpoint + '-'} , 'padding-right')
		@include list-loop('.pb-', #{$breakpoint + '-'} , 'padding-bottom')
		@include list-loop('.pl-', #{$breakpoint + '-'} , 'padding-left')
		@include list-loop('.p-', #{$breakpoint + '-'} , 'padding')
