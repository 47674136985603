.home-banner
	.banner-item
		position: relative
		.caption
			position: absolute
			left: 50%
			transform: translateX(-50%)
			width: 1540px
			bottom: r(80px)
			line-height: 1.375
			max-width: 100%
			padding: 0 r(30px)
		.title
			font-size: r(24px)
			color: #fff
			text-shadow: 0 3px 5px rgba(51, 51, 51, 0.75)
		.desc
			font-family: $font-2
			color: color(main)
			font-size: r(70px)
			text-shadow: 0 3px 5px rgba(51, 51, 51, 0.75)
	@media screen and ( max-width: 1440.98px )
		.banner-item
			.caption
				bottom: r(40px)
			.desc
				font-size: r(40px)
	@media screen and ( max-width: 1024.98px )
		.banner-item
			.img
				height: 480px
				img
					width: 100%
					height: 100%
					object-fit: cover
			.caption
				bottom: 30px
				padding: 0 15px
			.desc
				font-size: r(30px)