.publication-category
	background: #333
	color: #fff

.news-category
	& + .news-category
		border-top: 1px solid rgba(#fff , 0.1)

.news-category-slider
	.swiper-slide
		height: auto
		.news-item
			height: 100%
	.swiper-navigation-wrap
		position: absolute
		top: 50%
		transform: translateY(-50%)
		left: 0
		width: 100%
		pointer-events: none
		z-index: 2
		.swiper-btn
			pointer-events: auto
			position: static
	.swiper-navigation
		display: flex
		align-items: center
		justify-content: space-between
	@media screen and ( min-width: 1440.98px )
		.swiper-navigation-wrap
			.swiper-btn
				margin: 0 -70px

.news-side-list,.news-side-slider
	height: 615px !important
	.news-side
		padding-bottom: 20px
		border-bottom: 1px solid rgba(#fff , 0.1)
		height: 100%
	.swiper-container
		height: 100%
		