.logo-slider
	.item
		// background: #fff
		height: 150px
		position: relative
		img
			position: absolute
			top: 50%
			left: 50%
			transform: translate(-50%,-50%)
			max-height: 60%
	@media screen and ( max-width: 576px )
		.item
			height: 120px