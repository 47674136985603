footer
	color: #999
	line-height: 1.5

	.logo
		img
			height: 60px

	.footer-top,.footer-bottom
		padding: r(25px) 0
		border-top: 2px solid #333333

	.footer-title
		font-weight: 600
		font-size: r(17px)
		text-transform: uppercase
		margin-bottom: r(20px)

	.footer-info
		ul
			li
				display: flex
				font-size: r(14px)
				span
					margin-right: 8px
					position: relative
					top: 4px
				& + li
					margin-top: r(10px)

	.footer-social
		ul
			display: flex
			align-items: center
			font-size: 20px
			color: #fff
			li
				& + li
					margin-left: 15px

	.copyright
		font-size: 12px

.fixed-tool
	position: fixed
	bottom: 1rem
	right: 1rem
	z-index: 99
	@media screen and ( max-width: 1024.98px )
		flex-direction: column-reverse
		align-items: flex-end

.tool-contact
	a
		display: flex
		align-items: center
		transition: all 0.3s ease-in-out
		margin: 0 5px
		background-color: #333
		padding: 0 15px
		height: 40px
		color: #fff
		em
			display: flex
			color: #fff
			font-size: 1.25rem
			font-weight: 400
		span
			margin-left: 10px
			color: #fff
			font-size: 0.875rem
			font-weight: 700
			text-transform: uppercase
	@media screen and ( max-width: 1024.98px )
		flex-direction: column
		a
			margin: 5px 0
			width: 40px
			padding: 0
			justify-content: center
			span
				display: none

.back-to-top
	width: 40px
	height: 40px
	display: flex
	align-items: center
	justify-content: center
	transition: all 0.5s cubic-bezier(0.19, 1, 0.22, 1)
	background-color: color(main)
	margin-left: 5px
	color: #fff
	font-size: 20px
	@media screen and ( max-width: 1024.98px )
		margin-left: 0
		margin-bottom: 5px