.modulepager
	margin-top: r(40px)
	.pagination
		display: flex
		align-items: center
		justify-content: center
		li
			list-style: none
			display: inline-block
			text-align: center
			margin-left: r(10px)
			&:first-child
				margin-left: 0
			a,span
				justify-content: center
				align-items: center
				display: flex
				text-decoration: none
				color: color(main)
				background: #333
				font-weight: bold
				font-size: r(20px)
				font-family: $font-2
				width: r(40px)
				height: r(40px)
				font-feature-settings: 'lnum', 'pnum'
			&.active,&:hover
				a,span
					color: #fff
					background: $gradient
			.FirstPage,.LastPage,.BackPage,.NextPage
				display: none !important
			// .BackPage
			// 	font-size: 0
			// 	&:before
			// 		font-family: remixicon!important
			// 		content: "\EA64"
			// 		font-size: 15px
			// .NextPage
			// 	font-size: 0
			// 	&:before
			// 		font-family: remixicon!important
			// 		font-size: 15px
			// 		content: "\EA6E"
