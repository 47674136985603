.breadcrumb-wrapper
	margin-top: r(30px)
	.breadcrumb
		display: flex
		align-items: center
		list-style: none
		padding-left: 0
		overflow: hidden
		li
			color: color(main)
			white-space: nowrap
			a
				font-size: 15px
			+ li
				padding-left: 5px
				display: flex
				align-items: center
				&::before
					content: "/"
					margin-right: 5px
			&:last-child
				a
					color: map-get($colors, blue)
	
