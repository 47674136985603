.home-2
	padding-top: 0 !important
	.ModuleContent
		height: 100%
	.item
		position: relative
		height: 100%
		color: #fff
		padding-bottom: r(40px)
		border-bottom: 2px solid rgba(color(main) , 0.2)
		.img
			+img-scale(300 / 600 * 100%)
		.title
			height: r(50px)
			width: r(400px)
			max-width: 100%
			display: flex
			align-items: center
			justify-content: center
			margin: -25px auto 0
			background: color(main)
			font-family: $font-2
			font-weight: 900
			font-size: r(17px)
			text-align: center
			position: relative
			z-index: 2
			text-transform: uppercase
			padding: 0 20px
		.brief
			text-align: center
			line-height: 1.5
			margin-top: r(30px)
		.view-more
			margin-top: r(30px)
	@media screen and ( min-width: 1024.98px )
		.item
			.brief
				width: r(400px)
				margin: r(30px) auto 0
	@media screen and ( max-width: 768px )
		.item
			padding-bottom: r(30px)
			.title
				position: static
				width: auto
				margin-top: 0