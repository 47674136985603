.section
	padding: r(60px) 0
	@media screen and ( max-width: 1024.98px )
		padding: r(40px) 0

.section-small
	padding: r(30px) 0

.section-heading
	display: flex
	align-items: center
	justify-content: space-between
	margin-bottom: r(30px)
	.section-title
		margin-bottom: 0 !important
	@media screen and ( max-width: 576px )
		.btn
			min-width: auto
			padding: 0 r(15px)
			margin-left: r(15px)
			white-space: nowrap