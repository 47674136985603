.project-detail-1
	padding: r(67px) 0 0
	position: relative
	// &::before
	// 	position: absolute
	// 	content: ''
	// 	left: 0
	// 	bottom: 0
	// 	height: 410px
	// 	width: calc(50% - 15px)
	// 	background: color(main)
	// 	z-index: -1
	.content
		color: #fff
		padding-left: r(30px)
	.fullcontent
		line-height: 1.5
		font-size: r(15px)
		table
			width: 100%
			background: #333
			border-top: 2px solid color(main)
			tr
				td
					padding: r(16px) r(13px)
					&:first-child
						white-space: nowrap
						color: color(main)
				+ tr
					td
						border-top: 1px solid rgba(#fff , 0.1)
	@media screen and ( min-width: 1024.98px )
		.img
			padding-top: r(13px)
	@media screen and ( max-width: 1024.98px )
		padding: r(40px) 0 0
		.content
			padding: 0
		&::before
			display: none

.project-detail-2
	.item-list
		background-repeat: no-repeat
		background-size: cover
		background-position: center
		min-height: 390px
		display: flex
		align-items: center
		padding: r(30px)
		position: relative
		z-index: 2
		&:after
			position: absolute
			content: ''
			width: 100%
			height: 100%
			top: 0
			left: 0
			background: rgba(#000 , 0.3)
			z-index: -1
		.row
			width: calc(100% + 1.875rem)
		.item
			text-align: center
			.icon
				height: 70px
				display: flex
				align-items: center
				justify-content: center
			.subtitle
				text-transform: uppercase
				font-size: r(35px)
				line-height: 1.375
				font-weight: 700
				color: color(main)
				font-family: $font-2
				small
					font-size: 70%
			.title
				font-weight: 500
				font-size: r(18px)
				line-height: 1.375
				margin-top: r(4px)
				color: #fff
	.project-photo-list
		margin-top: r(60px)
		.item
			+img-scale(470 / 810 * 100%)
			&.big
				padding-top: 480 / 810 * 100%
			& + .item
				margin-top: r(30px)
	@media screen and ( max-width: 576px )
		.item-list
			.item
				.subtitle
					font-size: r(24px)

.project-detail-3
	margin-top: r(60px)
	.item
		background: #333
		color: #fff
		.img
			img
				width: 100%
				height: 100%
				object-fit: cover
		.content
			display: flex
			flex-direction: column
			justify-content: center
			padding: r(50px)
			.title
				font-size: r(24px)
				font-weight: 700
				color: #fff
				font-family: $font-2
				line-height: 1.5
				margin-bottom: r(40px)
			.btn
				width: 200px
	@media screen and ( max-width: 1024.98px )
		.item
			.row
				flex-direction: column-reverse
			.content
				padding: r(30px)