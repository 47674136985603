.detail-meta
	display: flex
	align-items: center
	padding: 12px 0
	border-top: 1px solid rgba(#fff , 0.1)
	border-bottom: 1px solid rgba(#fff , 0.1)
	.news-date
		margin-bottom: 0
		color: color(main)
	.share
		margin-left: r(105px)

.news-detail-content
	padding-top: r(30px)
	font-size: r(18px)
